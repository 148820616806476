
import { defineComponent, onMounted, ref, watch } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { useDirectors, useDeleteDirector } from '@/composables/api';
import { DirectorIdOption } from '@/services/api';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import SearchBox from '@/components/keyword-searching/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';


export default defineComponent({
  components: {
    SearchBox,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const keyword = ref();
    const status = ref();
    const route = useRoute();
    const sortBy = ref();
    const descSortBy = ref('updated_at');
    const { t } = useI18n();


    const { data, isLoading, isFetching, refetch } = useDirectors({ page, keyword, sortBy, descSortBy });
    const { isLoading: isDeletedLoading, mutate } = useDeleteDirector();

    const statusIndex = ref(undefined);

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      status.value = event[0];
    };

    onMounted(() => {
      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    const deleteDirector = ({ directorId }: DirectorIdOption, index: Number) => {
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          statusIndex.value = index;
          mutate(
            { directorId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    return {
      page,
      isLoading,
      isFetching,
      data,
      isDeletedLoading,
      deleteDirector,
      statusIndex,
      sortChange,
      t,
      searchKeyword
    };
  }
});
